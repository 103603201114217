import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  customerId: any = 'null';
  identityUrl: any = 'null';
  constructor() {
    if ("customerid" in localStorage &&  "identity" in localStorage) {
      this.customerId = localStorage.getItem('customerid');
      this.identityUrl = localStorage.getItem('identity');
    } else {
      this.customerId = this.customerId;
      this.identityUrl = this.identityUrl;
    }
    this.customerId = window.btoa(this.customerId);
    this.identityUrl = window.btoa(this.identityUrl);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add custom headers
    // const modifiedReq = req.clone({
    //   setHeaders: {
    //     'customerId': this.customerId,
    //     'identityUrl' : this.identityUrl,
    //   }
    // });
    // return next.handle(modifiedReq);

    let headers = req.headers;
    if (!headers.has('customerId')) {
        headers = headers.set('customerId', this.customerId);
        headers = headers.set('identityUrl', this.identityUrl);
    }
    const modifiedReq = req.clone({ headers });

    // Pass the modified request to the next handler
    return next.handle(modifiedReq);
  }
}


