import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import * as bcrypt from 'bcryptjs';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {Localstorage} from '../common/localstorage';
import {TokenService} from '../common/token.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Lookup} from '../common/lookup';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private http: HttpClient, private router: Router,
              private route: ActivatedRoute, private title: Title, private Localsecurestorage: Localstorage,
              private Token: TokenService, private lookUp: Lookup) {
  }

  clientIp: any;
  code;
  ipAddress: any;
  LoginForm: FormGroup;
  userDetail: object = {};
  errorMessage = '';
  loginMessage = '';
  id: string;
  userid: string;
  status: boolean;
  licenseDetails = [];
  employerDetails: object = [];
  productsettingapi: any;
  globalsettingapi: any;
  addresstypes = [];
  registeredId: any;
  pageRow;
  advancePageRow;
  business_id;
  linkexpirationtime = localStorage.getItem('linkexpirationtime');
  fieldTextType: boolean = true;
  pathopen = "M10 4.143c-3.618 0-6.745 2.094-8.253 5.138a1.62 1.62 0 0 0 0 1.438c1.508 3.044 4.635 5.138 8.253 5.138 3.618 0 6.745-2.094 8.253-5.138a1.62 1.62 0 0 0 0-1.438C16.745 6.237 13.618 4.143 10 4.143Zm0 9.762A3.902 3.902 0 0 1 6.104 10 3.902 3.902 0 0 1 10 6.095c2.15 0 3.896 1.75 3.896 3.905A3.902 3.902 0 0 1 10 13.905Zm0-6.248A2.337 2.337 0 0 0 7.662 10a2.337 2.337 0 1 0 4.676 0A2.337 2.337 0 0 0 10 7.657Z";
  pathclose = "M10.004 6.098A3.899 3.899 0 0 1 13.9 9.995c0 .507-.101.982-.28 1.426l2.275 2.276a9.226 9.226 0 0 0 2.355-2.983 1.617 1.617 0 0 0 0-1.438A9.212 9.212 0 0 0 9.995 4.15a9.077 9.077 0 0 0-3.102.546l1.683 1.683c.444-.179.92-.28 1.427-.28Zm-7.3-2.623a.7.7 0 0 0 0 .99l1.282 1.282.359.359a9.21 9.21 0 0 0-2.595 3.17 1.618 1.618 0 0 0 0 1.439 9.212 9.212 0 0 0 11.668 4.471l.327.328 1.788 1.782a.7.7 0 0 0 .99-.992L3.694 3.474a.7.7 0 0 0-.99 0ZM6.52 8.28l1.209 1.208a2.199 2.199 0 0 0-.063.507 2.335 2.335 0 0 0 2.339 2.338c.171 0 .343-.023.506-.062l1.208 1.208c-.522.257-1.099.413-1.714.413a3.899 3.899 0 0 1-3.898-3.897c0-.616.156-1.193.413-1.715Zm3.36-.608 2.455 2.455.016-.124a2.335 2.335 0 0 0-2.339-2.339l-.132.008Z";

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  getGlobalSettingApi() {
    this.http.get(Configuration.serviceIdentityURL + '/getglobalsettingapi').subscribe(globalsettingdata => {
      this.globalsettingapi = globalsettingdata;
      let identity = this.globalsettingapi.find(x => x.name == 'identity');
      if (!Utility.isUndefined(identity))
        localStorage.setItem('identity', identity['url']);
      let core = this.globalsettingapi.find(x => x.name == 'core');

      if (!Utility.isUndefined(core))
        localStorage.setItem('serviceCoreURL', core['url']);
      let storage = this.globalsettingapi.find(x => x.name == 'storage');
      if (!Utility.isUndefined(storage))
        localStorage.setItem('serviceStorageURL', storage['url']);

      let analytics = this.globalsettingapi.find(x => x.name == 'analytics');
      if (!Utility.isUndefined(analytics))
        localStorage.setItem('serviceAnalyticsURL', analytics['url']);

      let integration = this.globalsettingapi.find(x => x.name == 'integration');
      if (!Utility.isUndefined(integration))
        localStorage.setItem('serviceIntegrationURL', integration['url']);

      let externalapps = this.globalsettingapi.find(x => x.name == 'externalapps');
      if (!Utility.isUndefined(externalapps))
        localStorage.setItem('serviceExternalAppsURL', externalapps['url']);
    });
  }

  getProductSettingaApis(customerId,identityUrl) {
    const headers = new HttpHeaders()
      .set('customerId', window.btoa(customerId))
      .set('identityUrl', window.btoa(identityUrl));
    this.http.get(Configuration.serviceCoreURL + '/getproductsettingapis', { headers }).subscribe(productsettingapidata => {
      this.productsettingapi = productsettingapidata;
      let externalapps = this.productsettingapi.find(x => x.name == 'externalapps');
      if (!Utility.isUndefined(externalapps))
        localStorage.setItem('serviceExternalAppsURL', externalapps['url']);

      let identity = this.productsettingapi.find(x => x.name == 'identity');
      if (!Utility.isUndefined(identity))
        localStorage.setItem('serviceIdentityURL', identity['url']);

      let notification = this.productsettingapi.find(x => x.name == 'notification');
      if (!Utility.isUndefined(notification))
        localStorage.setItem('serviceNotificationURL', notification['url']);
      let license = this.productsettingapi.find(x => x.name == 'license');
      if (!Utility.isUndefined(license))
        localStorage.setItem('serviceLicenceURL', license['url']);

      let logging = this.productsettingapi.find(x => x.name == 'logging');
      if (!Utility.isUndefined(logging))
        localStorage.setItem('serviceLoggingURL', logging['url']);

      let s3bucketpath = this.productsettingapi.find(x => x.name == 's3bucketpath');
      if (!Utility.isUndefined(s3bucketpath))
        localStorage.setItem('s3BucketPathUrl', s3bucketpath['url']);

      let junctionboxpath = this.productsettingapi.find(x => x.name == 'junctionboxpath');
      if (!Utility.isUndefined(junctionboxpath))
        localStorage.setItem('junctionBoxPathUrl', junctionboxpath['url']);

      let junctionboxsubdomain = this.productsettingapi.find(x => x.name == 'junctionboxsubdomain');
      if (!Utility.isUndefined(junctionboxsubdomain))
        localStorage.setItem('junctionBoxSubDomain', junctionboxsubdomain['url']);

      let froalaeditorkey = this.productsettingapi.find(x => x.name == 'froalaeditorkey');
      if (!Utility.isUndefined(froalaeditorkey))
        localStorage.setItem('froalaEditorKey', froalaeditorkey['url']);

      let databoxpath = this.productsettingapi.find(x => x.name == 'databoxpath');
      if (!Utility.isUndefined(databoxpath))
        localStorage.setItem('dataBoxPathUrl', databoxpath['url']);

      let admin = this.productsettingapi.find(x => x.name == 'admin');
      if (!Utility.isUndefined(admin))
        localStorage.setItem('serviceAdminCoreURL', admin['url']);

      let indiamartkey = this.productsettingapi.find(x => x.name == 'indiamartkey');
      if (!Utility.isUndefined(indiamartkey))
        localStorage.setItem('indiamartKey', indiamartkey['url']);

      let indiamartNumber = this.productsettingapi.find(x => x.name == 'indiamartnumber');
      if (!Utility.isUndefined(indiamartNumber))
        localStorage.setItem('indiamartNumber', indiamartNumber['url']);

      let tradeindiaKey = this.productsettingapi.find(x => x.name == 'tradeindiakey');
      if (!Utility.isUndefined(tradeindiaKey))
        localStorage.setItem('tradeindiaKey', tradeindiaKey['url']);

      let tradeindiaUserId = this.productsettingapi.find(x => x.name == 'tradeindiauserid');
      if (!Utility.isUndefined(tradeindiaUserId))
        localStorage.setItem('tradeindiaUserId', tradeindiaUserId['url']);

      let tradeindiaProfileId = this.productsettingapi.find(x => x.name == 'tradeindiaprofileid');
      if (!Utility.isUndefined(tradeindiaProfileId))
        localStorage.setItem('tradeindiaProfileId', tradeindiaProfileId['url']);

      let flipkartkey = this.productsettingapi.find(x => x.name == 'flipkartkey');
      if (!Utility.isUndefined(flipkartkey))
        localStorage.setItem('flipkartKey', flipkartkey['url']);

      let amazonrefreshtoken = this.productsettingapi.find(x => x.name == 'amazonrefreshtoken');
      if (!Utility.isUndefined(amazonrefreshtoken))
        localStorage.setItem('amazonRefreshToken', amazonrefreshtoken['url']);

      let amazonclientid = this.productsettingapi.find(x => x.name == 'amazonclientid');
      if (!Utility.isUndefined(amazonclientid))
        localStorage.setItem('amazonClientId', amazonclientid['url']);

      let amazonclientsecrete = this.productsettingapi.find(x => x.name == 'amazonclientsecrete');
      if (!Utility.isUndefined(amazonclientsecrete))
        localStorage.setItem('amazonClientSecrete', amazonclientsecrete['url']);

      let payuKey = this.productsettingapi.find(x => x.name == 'payukey');
      if (!Utility.isUndefined(payuKey))
        localStorage.setItem('payuKey', payuKey['url']);

      let payuSalt = this.productsettingapi.find(x => x.name == 'payusalt');
      if (!Utility.isUndefined(payuSalt))
        localStorage.setItem('payuSalt', payuSalt['url']);

      let abstractkey = this.productsettingapi.find(x => x.name == 'abstractkey');
      if (!Utility.isUndefined(abstractkey))
        localStorage.setItem('abstractKey', abstractkey['url']);

      let mailUsername = this.productsettingapi.find(x => x.name == 'MAIL_USERNAME');
      if (!Utility.isUndefined(mailUsername))
        localStorage.setItem('MAIL_USERNAME', mailUsername['url']);

      let mailPassword = this.productsettingapi.find(x => x.name == 'MAIL_PASSWORD');
      if (!Utility.isUndefined(mailPassword))
        localStorage.setItem('MAIL_PASSWORD', mailPassword['url']);

      let facebookToken = this.productsettingapi.find(x => x.name == 'facebooktoken');
      if (!Utility.isUndefined(facebookToken))
        localStorage.setItem('facebookToken', facebookToken['url']);

      let facebookAdId = this.productsettingapi.find(x => x.name == 'facebookadid');
      if (!Utility.isUndefined(facebookAdId))
        localStorage.setItem('facebookAdId', facebookAdId['url']);

      let linkexpirationtime = this.productsettingapi.find(x => x.name == 'linkexpirationtime');
      if (!Utility.isUndefined(linkexpirationtime))
        localStorage.setItem('linkexpirationtime', linkexpirationtime['url']);
    });
  }

  getEmployeeId(customerId,identityUrl,userId) {
    const headers = new HttpHeaders()
      .set('customerId', window.btoa(customerId))
      .set('identityUrl', window.btoa(identityUrl));
    this.http.get(Configuration.serviceCoreURL + '/getemployeeid?uid='+userId+'', { headers }).subscribe(data => {
      let useremployeedetails = data;
      if (!Utility.isUndefined(useremployeedetails))
        localStorage.setItem('employeeid', useremployeedetails[0]['employee_id']);
    });
  }

  validatelogin(login) {
    if (!this.LoginForm.valid) {
    } else {
      this.http.post(Configuration.serviceIdentityURL + '/login', login).subscribe((data :any) => {
        if (data === '' || data === null || (Array.isArray(data) && data.length === 0)) {
          this.errorMessage = 'Invalid Username or Password !';
        }
        else {
          this.status = bcrypt.compareSync(login.password, data.password);
          if (this.status === true) {
            localStorage.setItem('businessid', data.business_id);
            localStorage.setItem('customerid', data.customer_id);
            localStorage.setItem('identity', Configuration.serviceIdentityURL);
            localStorage.setItem('rolename', data.rolename);
            this.getProductSettingaApis(data.customer_id, Configuration.serviceIdentityURL);
            this.getEmployeeId(data.customer_id, Configuration.serviceIdentityURL,data.id);
            this.getPageRow(data.customer_id, Configuration.serviceIdentityURL);
            this.getAdvancePageRow(data.customer_id, Configuration.serviceIdentityURL);
            this.userDetail = data;
            if (this.userDetail['isdisabled'] == true) {
              this.errorMessage = 'Your account has been disabled, please contact administrator.';
              // } else if (data['resetpassword'] == 0) {
              //   this.redirectNewUser(data);
            } else {
              this.http.post(Configuration.serviceIdentityURL + '/authorizelogin', {
                email: login.username,
                url: Configuration.serviceIdentityURL
              }).subscribe(res => {
                let token = res;
                // this._messageService.setUser(token);
                localStorage.setItem('userid', this.userDetail['id']);
                // this.Token.setValueinLocalstorage(token);
                localStorage.setItem('token', token['token']);
                this.http.post(Configuration.serviceIdentityURL + '/authorizeupdatetoken', {
                  id: localStorage.getItem('userid'),
                  token: token['token'],
                  url: Configuration.serviceIdentityURL
                }).subscribe((data) => {
                  this.Localsecurestorage.setStorage('useuserid', this.userDetail['id']);
                  this.Localsecurestorage.setStorage('useroleid', this.userDetail['roleid']);
                  this.Localsecurestorage.setStorage('useemail', this.userDetail['email']);
                  this.Localsecurestorage.setStorage('useusername', this.userDetail['username']);
                  this.Localsecurestorage.setStorage('useemployeeid', this.userDetail['employee_id']);
                  this.Localsecurestorage.setStorage('uselandingroute', this.userDetail['landingroute']);
                  localStorage.setItem('email', this.userDetail['email']);
                  localStorage.setItem('roleid', this.userDetail['roleid']);
                  localStorage.setItem('username', this.userDetail['username']);
                  localStorage.setItem('firstname', this.userDetail['firstname']);
                  localStorage.setItem('lastname', this.userDetail['lastname']);
                  localStorage.setItem('rolename', this.userDetail['rolename']);
                  localStorage.setItem('userid', this.userDetail['id']);
                  localStorage.setItem('profilephoto', this.userDetail['profilephoto']);
                  localStorage.setItem('aliasrolename', this.userDetail['aliasrolename']);
                  localStorage.setItem('landingroute', this.userDetail['landingroute']);
                  localStorage.setItem('password', login.password);
                  localStorage.setItem('customernumber', this.userDetail['customernumber']);
                  localStorage.setItem('activetab', 'lead');
                  if (this.userDetail['landingroute'] !== null) {
                    // this.router.navigateByUrl(this.userDetail['landingroute']);
                    setTimeout(() => {
                      let initialurl = window.location.href;
                      var urlarrya = initialurl.split( '/' );
                      var landingurl = urlarrya[0]+'//'+urlarrya[2]+this.userDetail['landingroute'];
                      window.location.href = landingurl;
                    }, 5000);  //5s
                  } else {
                    this.router.navigate(['/**']);
                  }
                });
                //  localStorage.setItem('token', token['token']);
              });

              // this.localService.setJsonValue('user', login.password);
              // this._messageService.setUser(this.userDetail);
              this.userid = this.userDetail['id'];
              // this.http.get(Configuration.serviceCoreURL + '/getbusinessid?user_id=' + this.userid).subscribe((businessid: any) => {
              //   if (!Utility.isNull(businessid)) {
              //     localStorage.setItem('businessid', businessid);
              //     this.business_id = businessid;
              //     this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(empdata => {
              //       this.employerDetails = empdata;
              //       localStorage.setItem('companyname', this.employerDetails['nameoforganization']);
              //       localStorage.setItem('companymobileno', this.employerDetails['mobilenoofowner']);
              //       localStorage.setItem('companyshortname', this.employerDetails['companyshortname']);
              //     });
              //     // this.business_id = businessid.json();
              //   }
              // });
              let email = {
                'username':localStorage.getItem('MAIL_USERNAME'),
                'password':localStorage.getItem('MAIL_PASSWORD'),
              };
              this.http.post(Utility.serviceIntegrationURL() + '/setemailconfiguration',email ).subscribe((data) => {
              });
              const headers = new HttpHeaders()
                .set('customerId', window.btoa(localStorage.getItem('customerid')))
                .set('identityUrl', window.btoa(localStorage.getItem('identity')));
              this.http.get(Configuration.serviceCoreURL + '/getdocumentsizelimit',{headers}).subscribe((docsizelimit: any) => {
                if (!Utility.isNull(docsizelimit)) {
                  localStorage.setItem('docsizelimit', docsizelimit);
                }
              });
              this.UpdateLoggedUserDetail(this.userDetail['id'], localStorage.getItem('customerid'), localStorage.getItem('identity'));
            }
          }
          else {
            this.errorMessage = 'Invalid Username and Password !';
          }
        }
      });
    }
  }

  redirectNewUser(data) {
    const userid = data['id'];
    const forgottoken = uuidv4();
    const userinfo = {
      id: userid,
      forgottoken: forgottoken,
      linkexpirationtime: this.linkexpirationtime
    };
    this.http.post(Configuration.serviceCoreURL + '/generateforgotpasswordtoken', userinfo).subscribe(data => {
      this.router.navigate(['/resetpassword', forgottoken]);
    });
  }

  UpdateLoggedUserDetail(id, customerId, identityUrl) {
    const headers = new HttpHeaders()
      .set('customerId', window.btoa(customerId))
      .set('identityUrl', window.btoa(identityUrl));
    this.http.get<{ ip: string }>('https://jsonip.com').subscribe(data => {
      this.ipAddress = data;
      this.clientIp = this.ipAddress['ip'];
      this.http.get(Configuration.serviceIdentityURL + '/updateloggeduserdetail?id=' + id + '&ip=' + this.clientIp).subscribe(res => {
      });
    });

  }

  getPageRow(customerId, identityUrl) {
    const headers = new HttpHeaders()
      .set('customerId', window.btoa(customerId))
      .set('identityUrl', window.btoa(identityUrl));
    this.http.get(Configuration.serviceCoreURL + '/getdefaultrowvalue?code=NOOFROW',{ headers }).subscribe(data => {
      if (data !== '' && data !== null && data !== undefined) {
        this.pageRow = data;
      } else {
        this.pageRow = 20;
      }
      localStorage.setItem('pageSize', this.pageRow);
    });
  }

  getAdvancePageRow(customerId, identityUrl) {
    const headers = new HttpHeaders()
      .set('customerId', window.btoa(customerId))
      .set('identityUrl', window.btoa(identityUrl));
    this.http.get(Configuration.serviceCoreURL + '/getdefaultrowvalue?code=ADVANCENOOFROWS',{ headers }).subscribe(data => {
      if (data !== '' && data !== null && data !== undefined) {
        this.advancePageRow = data;
      } else {
        this.advancePageRow = 50;
      }
      localStorage.setItem('advancepageSize', this.advancePageRow);
    });
  }

  ngOnInit() {
    this.getGlobalSettingApi();
    this.id = localStorage.getItem('userid');
    this.title.setTitle('Octanics - Login');
    this.Token.checkTokenAtLogin();
    // this.lookUp.getLoginLookUp('addresstypes').subscribe((data) => {
    //   if (data) {
    //     this.addresstypes = data;
    //     let type = this.addresstypes.find(x => x.code == 'REGISTERED');
    //     this.registeredId = type['id'];
    //   }
    // });
    this.LoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
    this.toggleFieldTextType();
  }

}
